// @ts-ignore
import notificationIcon from "../../assets/Notification.svg";
// @ts-ignore
import settingsIcon from "../../assets/settings-icon.svg";
import React, { useContext, useMemo, useRef } from "react";
import { useCurrentUser } from "../../components/Auth/UseCurrentUser";
import { useLocation, useNavigate } from "react-router-dom";
import { useMutation, useQueryClient } from "react-query";
import axios from "axios";
import classNames from "classnames";
import { Context } from "../../helpers/Context";
import NavLinks from "./NavLinks";
import NavBarActionBtns from "./NavBarActionBtns";
import config from "../../config";
import { useIsHidden } from "../../hooks/useIsHidden";
import DropdownSelect from "../DropdownSelect/DropdownSelect";
import { useIsPage } from "../../hooks/useIsPage";
// @ts-ignore
import lockedIcon from "../../assets/locked-icon-black.svg";
import NotificationsDropdown from "../Notifications/NotificationsDropdown";

const mockednotifs = [
  {
    message:
      "lorem ipsum blablabla lorem ipsum blablabla lorem ipsum blablabla lorem ipsum blablabla lorem ipsum blablabla lorem ipsum blablabla",
    timestamp: "1h ago",
  },
  {
    message:
      "lorem ipsum blablabla lorem ipsum blablabla  lorem ipsum blablabla lorem ipsum blablabla",
    timestamp: "1h ago",
  },
  {
    message: "lorem ipsum  blablabla lorem ipsum blablabla",
    timestamp: "1h ago",
    isViewed: true,
  },
];

const NavBarDesktop: React.FC = () => {
  const { isHidden, handleHiddenToggle } = useIsHidden();
  const { isHidden: isHiddenNotifs, handleHiddenToggle: handleNotifToggle } =
    useIsHidden();
  const { handleToggleExpandable, report } = useContext(Context);
  const { data: currentUser } = useCurrentUser();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const location = useLocation();
  const exclusionRef = useRef<HTMLImageElement | null>(null);
  const exclusionRefNotif = useRef<HTMLImageElement | null>(null);
  const { isDashboard, isHome, isGeneticTestingInstruction } = useIsPage();
  const products = report.products || [];
  const isButtonActive = isDashboard && products;
  const isAdmin = currentUser?.user?.role === "ADMIN";
  const logoutMutation = useMutation(
    async () => {
      await axios.post(`${config.apiBaseURL}/auth/logout`, null, {
        withCredentials: true,
      });
    },
    {
      onSuccess: () => {
        queryClient.setQueryData("currentUser", {
          authenticated: false,
          user: null,
        });
        navigate("/sign-in");
      },
    }
  );

  const handleLogout = async () => {
    await logoutMutation.mutateAsync();
  };

  const dropdownOptions = useMemo(() => {
    const options = [
      {
        name: "Settings",
        value: "/settings",
        onClick: () => {
          navigate("/settings");
          handleHiddenToggle();
        },
      },
      {
        name: "Logout",
        value: "/logout",
        onClick: () => {
          handleLogout();
          handleHiddenToggle();
        },
      },
    ];

    if (isAdmin) {
      options.splice(1, 0, {
        name: "Admin Page",
        value: "/admin",
        onClick: () => {
          navigate("/admin");
          handleHiddenToggle();
      },
      });
    }

    return options;
  }, [isAdmin, navigate, handleLogout]);

  return (
    <div className="Navbar">
      {!isHome && !isGeneticTestingInstruction && <NavLinks />}
      <div className="RightItems">
        <NavBarActionBtns />
        <div
          className={classNames(
            "personal-supplement-button",
            "NavItemContainer",
            isButtonActive && "active"
          )}
          onClick={() =>
            isButtonActive && handleToggleExpandable && handleToggleExpandable()
          }
        >
          {!isHome && !isGeneticTestingInstruction && (
            <div
              className={classNames("NavItem-Buttons", {
                // active: active === "personalSupplement",
              })}
            >
              Personal Supplement
              {!isButtonActive && (
                <img
                  src={lockedIcon}
                  alt="locked icon"
                  className="NavItemIcon"
                />
              )}
            </div>
          )}
        </div>
        <div className="ExtraIcons">
          {isHidden && (
            <DropdownSelect
              toggleHidden={handleHiddenToggle}
              classNameOverwrite="ExtraIcons-Dropdown"
              active={location.pathname}
              options={dropdownOptions}
              exclusionRefs={[exclusionRef]}
            />
          )}
          <img
            ref={exclusionRef}
            src={settingsIcon}
            alt="Settings icon"
            className="IconImage"
            onClick={handleHiddenToggle}
          />
          {/* <img
            ref={exclusionRefNotif}
            src={notificationIcon}
            alt="Notification icon"
            className="IconImage"
            onClick={() => handleNotifToggle()}
          /> */}
          {/* {isHiddenNotifs && (
            <NotificationsDropdown
              toggleHidden={handleNotifToggle}
              exclusionRefs={[exclusionRefNotif]}
              notifs={mockednotifs}
            />
          )} */}
        </div>
      </div>
    </div>
  );
};

export default NavBarDesktop;
