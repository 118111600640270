import React, { useState, useMemo, useEffect } from "react";
import config from "../../config";
import ChunkedUploady, {
  useChunkFinishListener,
  useBatchFinishListener,
} from "@rpldy/chunked-uploady";
import { UseGetUserById } from "../Auth/FetchUserById";
import UploadButton from "@rpldy/upload-button";
import uuid4 from "uuid4";
import axios from "axios";
import LinearProgress from "@mui/material/LinearProgress";
import "./admin.css";
import classNames from "classnames";

function AdminGeneticUpload(props) {
  const { userId, setErrorMessage, setErrorSnackbarOpen, setAllChunksSentUsers } = props;
  const requestID = useMemo(() => uuid4(), []);
  const [progress, setProgress] = useState(0);
  const { data: selectedUser } = UseGetUserById(userId);
  const userData = selectedUser?.users?.user;
  const hasReport = userData?.has_report;

  const TheApp = ({ children }) => {
    useChunkFinishListener(async (chunk) => {
      setProgress(chunk.item.completed);
      if (chunk.item.completed === 100) {
        await axios.put(
          `${config.apiBaseURL}/admin_all_chunks_accepted/${userId}`,
          null,
          { withCredentials: true }
        );
        setAllChunksSentUsers((prevUsers) => [...prevUsers, userId]);
      }
    });

    useBatchFinishListener((batch) => {
      batch.items.forEach((item) => {
        if (item.uploadResponse && item.state === "error") {
          setErrorSnackbarOpen(true);
          setErrorMessage("An error has occured.");
        }
      });
    });

    return children;
  };

  const getProgressBar = () => {
    const progressColor = progress === 100 ? "greenComplete" : "blueProgress";
    if (progress > 0 && progress < 100) {
      return (
        <div>
          <div className="styled-circle-progress-number">
            {`${progress.toFixed(0)} %`}
          </div>
          <LinearProgress
            variant="determinate"
            value={progress}
            color={progressColor}
            style={{ width: "100%" }}
          />
        </div>
      );
    } else if (progress === 100) {
      return (
        <div style={{ fontSize: "0.8rem", color: "green", marginTop: "5px" }}>
          Upload complete!
        </div>
      );
    }
    return null;
  };


  return (
    <div>
      <ChunkedUploady
        chunkSize={5000000}
        params={{ request_id: requestID }}
        chunked
        parallel={5}
      >
        <TheApp>
          <div className="admin-form-button-upload">
            <UploadButton
              className={classNames(
                "genetic-file-button",
                "adminGeneticUploadButton"
              )}
              destination={{
                url: `${config.apiBaseURL}/admin_genomic_data/${userId}`,
              }}
              withCredentials
            >
              {hasReport ? "Re-upload" : "Upload"}
            </UploadButton>
          </div>
        </TheApp>
      </ChunkedUploady>
      {getProgressBar()}
    </div>
  );
}

export default AdminGeneticUpload;
