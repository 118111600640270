import { useRef, useState } from "react";
import { useMutation } from "react-query";
import config from "../../config";
import { Field, Form, Formik } from "formik";
import { object, string } from "yup";
import * as Yup from "yup";
import { TextField, Snackbar, Alert } from "@mui/material";

import axios from "axios";
import "./admin.css";
import classNames from "classnames";
import ButtonWithIcon from "../ButtonWithIcon/ButtonWithIcon";

const initalValues = {
  first_name: "",
  last_name: "",
  email: "",
  password: "",
  confirm_password: "",
  dante_id: "",
};

const color = { color: "#8E95A9" };

const AdminRegister = ({ handleClose }) => {
  const [errorSnackbarOpen, setErrorSnackbarOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const registerMutation = useMutation(async (credentials) => {
    const { data } = await axios.post(
      `${config.apiBaseURL}/auth/admin_register`,
      credentials,
      {
        withCredentials: true,
      }
    );
    return data;
  });

  const handleRegister = async (values) => {
    try {
      await registerMutation.mutateAsync(values);
      handleClose();
    } catch (error) {
      setErrorSnackbarOpen(true);
      setErrorMessage(error.response.data.message);
    }
  };
  const formikRef = useRef();
  return (
    <div className="MaterialFormAdmin">
      <span className="admin-modal-title">Add New User</span>
      <Formik
        initialValues={initalValues}
        validationSchema={object({
          first_name: string()
            .required("Please enter first name")
            .min(2, "Name too short"),
          last_name: string()
            .required("Please enter last name")
            .min(2, "Name too short"),
          email: string().required("Please enter email").email("Invalid email"),
          password: string()
            .required("Please enter password")
            .min(7, "Password should be minimum 7 characters long"),
          confirm_password: Yup.string()
            .required()
            .oneOf([Yup.ref("password"), null], "Passwords must match"),
          dante_id: Yup.string(),
        })}
        onSubmit={async (values, formikHelpers) => {
          await handleRegister(values);
          formikHelpers.resetForm();
        }}
        innerRef={formikRef}
      >
        {({ errors, isValid, touched, dirty }) => (
          <Form>
            <label>First Name</label>
            <Field
              name="first_name"
              type="name"
              as={TextField}
              variant="outlined"
              color="primary"
              hiddenLabel
              fullWidth
              InputProps={{
                sx: color,
              }}
              error={Boolean(errors.first_name) && Boolean(touched.first_name)}
              helperText={Boolean(touched.first_name) && errors.first_name}
            />
            <label>Last Name</label>
            <Field
              name="last_name"
              type="name"
              as={TextField}
              variant="outlined"
              color="primary"
              hiddenLabel
              fullWidth
              InputProps={{
                sx: color,
              }}
              error={Boolean(errors.last_name) && Boolean(touched.last_name)}
              helperText={Boolean(touched.last_name) && errors.last_name}
            />
            <label>Email</label>
            <Field
              name="email"
              type="email"
              as={TextField}
              variant="outlined"
              InputProps={{
                sx: color,
              }}
              hiddenLabel
              fullWidth
              error={Boolean(errors.email) && Boolean(touched.email)}
              helperText={Boolean(touched.email) && errors.email}
            />
            <label>Password</label>
            <Field
              name="password"
              type="password"
              as={TextField}
              variant="outlined"
              color="primary"
              hiddenLabel
              fullWidth
              error={Boolean(errors.password) && Boolean(touched.password)}
              helperText={Boolean(touched.password) && errors.password}
            />
            <label>Confirm Password</label>
            <Field
              name="confirm_password"
              type="password"
              as={TextField}
              variant="outlined"
              color="primary"
              hiddenLabel
              fullWidth
              error={
                Boolean(errors.confirm_password) &&
                Boolean(touched.confirm_password)
              }
              helperText={
                Boolean(touched.confirm_password) && errors.confirm_password
              }
            />
            <label>Dante Tracking Id</label>
            <Field
              name="dante_id"
              type="text"
              as={TextField}
              variant="outlined"
              color="primary"
              hiddenLabel
              fullWidth
              error={Boolean(errors.dante_id) && Boolean(touched.dante_id)}
              helperText={Boolean(touched.dante_id) && errors.dante_id}
            />
          </Form>
        )}
      </Formik>
      <div className="admin-modal-buttons">
        <button
          type="button"
          onClick={handleClose}
          className={classNames("button", "admin-modal-cancel-btn")}
        >
          Cancel
        </button>
        <ButtonWithIcon
          classNameIconOverWrite="save-btn-icon"
          onClick={() => formikRef.current.submitForm()}
          text="Save Changes"
        />
      </div>
      <Snackbar
        open={errorSnackbarOpen}
        autoHideDuration={5000}
        onClose={() => setErrorSnackbarOpen(false)}
        sx={{ marginLeft: "400px" }}
      >
        <Alert onClose={() => setErrorSnackbarOpen(false)} severity="error">
          {errorMessage}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default AdminRegister;
