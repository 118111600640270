import React from "react";

interface TickIconProps {
  stroke: string;
}

const TickIcon: React.FC<TickIconProps> = ({ stroke }) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <polyline
      points="3.7 14.3 9.6 19 20.3 5"
      fill="none"
      stroke={stroke}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default TickIcon;
