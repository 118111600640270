import React, { useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useMutation } from "react-query";
import { TextField, Button, Snackbar, Alert } from "@mui/material";
import axios from "axios";
import config from "../../config";
import "./reset.css";

const PasswordResetForm: React.FC = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const token = searchParams.get("token");

  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [errorSnackbarOpen, setErrorSnackbarOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const resetPasswordMutation = useMutation(
    async (newPassword: string) => {
      const { data } = await axios.post(
        `${config.apiBaseURL}/reset-user-password`,
        { token, password: newPassword },
        {
          withCredentials: true,
        }
      );
      return data;
    },
    {
      onSuccess: () => {
        navigate("/sign-in");
      },
      onError: (error: any) => {
        setErrorSnackbarOpen(true);
        const message = error.response?.data.message || "An error occurred";
        setErrorMessage(message);
      },
    }
  );

  const handlePasswordReset = () => {
    if (password !== confirmPassword) {
      setErrorMessage("Passwords do not match");
      setErrorSnackbarOpen(true);
      return;
    }

    resetPasswordMutation.mutate(password);
  };

  return (
    <div className="password-reset-form-wrap">
      <h2>Reset Your Password</h2>
      <p>Please enter your new password below.</p>
      <TextField
        label="New Password"
        type="password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        fullWidth
        margin="normal"
        variant="outlined"
      />
      <TextField
        label="Confirm Password"
        type="password"
        value={confirmPassword}
        onChange={(e) => setConfirmPassword(e.target.value)}
        fullWidth
        margin="normal"
        variant="outlined"
      />
      <Button
        variant="contained"
        color="primary"
        fullWidth
        onClick={handlePasswordReset}
        className="reset-button"
      >
        Reset Password
      </Button>
      <Snackbar
        open={errorSnackbarOpen}
        autoHideDuration={6000}
        onClose={() => setErrorSnackbarOpen(false)}
        sx={{ marginLeft: "400px" }}
      >
        <Alert onClose={() => setErrorSnackbarOpen(false)} severity="error">
          {errorMessage}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default PasswordResetForm;
