import React, { useEffect, useContext, useState } from "react";
import { useLocation } from "react-router-dom";
import ProgressCard from "../Cards/ProgressCard";
import ReportInfo from "../ReportInfo/ReportInfo";
import GeneTable from "./GeneTable";
import { DASHBOARD } from "./constants";
import useResponsive from "../../hooks/useResponsive";
import { Context } from "../../helpers/Context";
import LoadingSpinner from "../../ui/Spinner/Spinner";

// Define the type for an effect
interface Effect {
  name: string;
  score: number;
  explanation: string;
  mainFunction: string;
  id: string;
  genotype: string;
  severity: string;
  identifier: string;
  description: string;
  meaning: string;
  snps: any;
  output_text: string;
}

const Dashboard: React.FC = () => {
  const { isMobile, isDesktop } = useResponsive();
  const { report, sortOrder, handleToggleExpandable, calculateReport, userId } = useContext(Context);
  const effects: Effect[] = report?.effects || [];
  const location = useLocation();
  const [drawerOpened, setDrawerOpened] = useState(false);
  
  useEffect(() => {
    if (!userId) {
      calculateReport();
    }
  }, [userId, calculateReport])

  useEffect(() => {
    if (location.state?.openDrawer && !drawerOpened) {
      handleToggleExpandable();
      setDrawerOpened(true);
    }
  }, [location.state, drawerOpened, handleToggleExpandable]);

  useEffect(() => {
    if (!isDesktop) {
      if (location.hash) {
        const id = location.hash.replace("#", "");
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView({ behavior: "smooth" });
        }
      }
    }
  }, [location.hash, isDesktop]);

  const sortEffects = (effectsArray: Effect[]): Effect[] => {
    return effectsArray.sort((a, b) => {
      if (sortOrder === "Sort High-Low") {
        return b.score - a.score; // Descending by score
      } else if (sortOrder === "Sort A-Z") {
        return a.name.localeCompare(b.name); // Alphabetical by name
      }
      return 0;
    });
  };

  if (effects.length === 0) {
    return <LoadingSpinner />;
  }

  const nutritionalEffects = sortEffects(
    effects.filter((effect) =>
      [
        "Antioxidant Requirement",
        "Caffeine Sensitivity",
        "Calcium Requirement",
        "Carbohydrate Sensitivity",
        "Fat Sensitivity",
        "Iron Requirement",
        "Lactose Tolerance",
        "Omega 3 Requirement",
        "Salt Sensitivity",
        "Vitamin A Requirement",
        "Vitamin B2 Requirement",
        "Vitamin B6 Requirement",
        "Vitamin B9 Requirement",
        "Vitamin B12 Requirement",
        "Vitamin C Requirement",
        "Vitamin D Requirement",
        "Vitamin E Requirement",
        //TODO - UNCOMMENT WHEN SORTED NEW REPORTS FOR OLD USERS  - 14/03/2025
        "Magnesium Requirement",
        "Choline Requirement",
        "Vitamin K",
        "Zinc Requirement",
        "Creatine Requirement"
      ].includes(effect.name)
    )
  );

  const trainingEffects = sortEffects(
    effects.filter((effect) =>
      [
        "Achilles Injury Risk",
        "ACL Injury Risk",
        "Aerobic (V02) Trainability",
        "Endurance Response",
        "Injury Predisposition",
        "Lower Back Injury Risk",
        "Motivation To Exercise",
        "Muscle Mass Predisposition",
        "Power Response",
        "Recovery Efficiency",
        "Strength Response",
      ].includes(effect.name)
    )
  );
  return (
    <div className={"LeftDiv"}>
      <section className="score-calc-info-section">
        <div id="health-report" className="basic-page-header">
          {DASHBOARD.title}
        </div>
        <div className="score-calc-card">
          <div className="report-info-header">
            <h3>{DASHBOARD.header}</h3>
            <div className="score-calc-description">
              {DASHBOARD.description}
            </div>
            {/* @ts-ignore */}
            {!isMobile && <GeneTable gene={{}} />}
          </div>
        </div>
      </section>
      {/* @ts-ignore */}
      {isMobile && <GeneTable gene={{}} />}
      <section className={"score-calc-info-section"}>
        <div className="nutrition-report-highlighted">
          <div id="nutritional-report" className="basic-page-header">
            Nutritional Report
          </div>
          <div className="card-container">
            {nutritionalEffects.map((data, index) => (
              <ProgressCard key={index} {...data} />
            ))}
          </div>
          <div className="additional-container">
            {nutritionalEffects.map((data, index) => (
              <ReportInfo key={index} {...data} />
            ))}
          </div>
        </div>
      </section>

      <section className={"score-calc-info-section"}>
        <div className="training-report-highlighted">
          <div id="training-report" className="basic-page-header">
            Training Report
          </div>
          <div className="card-container">
            {trainingEffects.map((data, index) => (
              <ProgressCard key={index} {...data} />
            ))}
          </div>
          <div className="additional-container">
            {trainingEffects.map((data, index) => (
              <ReportInfo key={index} {...data} />
            ))}
          </div>
        </div>
      </section>
    </div>
  );
};

export default Dashboard;
