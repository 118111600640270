import React, { useContext, useMemo, useRef } from "react";
import ExpandableSection from "../ExpandableSection/ExpandableSection";
import SmoothScrollLink from "../SmoothScrollLink/SmoothScrollLink";
import { useIsHidden } from "../../hooks/useIsHidden";
import { SORT } from "../../helpers/variables";
import { sorting } from "../../helpers/utils";
import { Context } from "../../helpers/Context";
import DropdownSelect from "../DropdownSelect/DropdownSelect";
import useActiveLink from "../../hooks/useActiveLink";
import classNames from "classnames";

// NOTE on how to use:
// the main component is SmoothScrollLink
// for it to work you need to provide unique identifier which you'll attach as id to the element you wanna navigate to
// and to SmoothScrollLink with "to-" prefix as a link

const ContentList: React.FC = () => {
  const exclusionRef = useRef<HTMLDivElement | null>(null);
  const { isHidden, handleHiddenToggle } = useIsHidden();
  const { sortOrder, setSortOrder, report } = useContext(Context);

  const handleSortToggle = (e: React.MouseEvent<HTMLSpanElement>) => {
    const value = (e.target as HTMLSpanElement).innerText;
    setSortOrder(value);
  };

  const dropdownOptions = useMemo(() => {
    return [
      {
        name: SORT.ALPHA,
        value: SORT.ALPHA,
        onClick: handleSortToggle,
      },
      {
        name: SORT.QUANTITY,
        value: SORT.QUANTITY,
        onClick: handleSortToggle,
      },
    ];
  }, []);
  //log

  const { active, handleLinkClick } = useActiveLink();

  // Filter the report effects based on the categories
  const nutritionalEffects = report?.effects?.filter((effect) =>
    [
      "Antioxidant Requirement",
      "Caffeine Sensitivity",
      "Calcium Requirement",
      "Carbohydrate Sensitivity",
      "Fat Sensitivity",
      "Iron Requirement",
      "Lactose Tolerance",
      "Omega 3 Requirement",
      "Salt Sensitivity",
      "Vitamin A Requirement",
      "Vitamin B2 Requirement",
      "Vitamin B6 Requirement",
      "Vitamin B9 Requirement",
      "Vitamin B12 Requirement",
      "Vitamin C Requirement",
      "Vitamin D Requirement",
      "Vitamin E Requirement",
      //TODO - UNCOMMENT WHEN SORTED NEW REPORTS FOR OLD USERS  - 14/03/2025
      "Magnesium Requirement",
      "Choline Requirement",
      "Vitamin K",
      "Zinc Requirement",
      "Creatine Requirement"
    ].includes(effect.name)
  );

  const trainingEffects = report?.effects?.filter((effect) =>
    [
      "Achilles Injury Risk",
      "ACL Injury Risk",
      "Aerobic (V02) Trainability",
      "Endurance Response",
      "Injury Predisposition",
      "Lower Back Injury Risk",
      "Motivation To Exercise",
      "Muscle Mass Predisposition",,
      "Power Response",
      "Recovery Efficiency",
      "Strength Response",
    ].includes(effect.name)
  );

  return (
    <>
      <div className="side-navigation-sort-wrap">
        <div
          ref={exclusionRef}
          className="side-navigation-sort-button"
          onClick={handleHiddenToggle}
        >
          <span>Sort</span>
          <div className="side-navigation-sort-button-icon" />
        </div>
        {isHidden && (
          <DropdownSelect
            exclusionRefs={[exclusionRef]}
            toggleHidden={handleHiddenToggle}
            classNameOverwrite="side-navigation-sort-togglable"
            active={sortOrder}
            options={dropdownOptions}
          />
        )}
      </div>
      <div className="expendablesWrap">
        <SmoothScrollLink
          onClick={() => handleLinkClick("health-report")}
          key={"health-report"}
          to={"health-report"}
          className={classNames(
            active === "health-report" && "active",
            "side-navigation-link"
          )}
        >
          <span>Overall Health Report Score</span>
        </SmoothScrollLink>

        <ExpandableSection
          text={"Nutritional Summary"}
          defaultCollapsed={false}
          isReportInfo={false}
        >
          <>
            <SmoothScrollLink
              onClick={() => handleLinkClick("nutritional-report")}
              key={"nutritional-report"}
              to={"nutritional-report"}
              className={classNames(
                active === "nutritional-report" && "active",
                "side-navigation-link"
              )}
            >
              <span>Your Nutritional Report</span>
            </SmoothScrollLink>
            {nutritionalEffects
              ?.sort((a, b) => sorting(a, b, sortOrder))
              .map((item) => (
                <SmoothScrollLink
                  onClick={() => handleLinkClick(item.identifier)}
                  key={item.identifier}
                  to={item.identifier}
                  className={classNames(
                    active === item.identifier && "active",
                    "side-navigation-link"
                  )}
                >
                  <span>{item.name}</span>
                </SmoothScrollLink>
              ))}
          </>
        </ExpandableSection>
        <ExpandableSection text={"Training Summary"} defaultCollapsed={false} isReportInfo={false}>
          <>
            <SmoothScrollLink
              onClick={() => handleLinkClick("training-report")}
              key={"training-report"}
              to={"training-report"}
              className={classNames(
                active === "training-report" && "active",
                "side-navigation-link"
              )}
            >
              <span>Your Training Report</span>
            </SmoothScrollLink>
            {trainingEffects
              ?.sort((a, b) => sorting(a, b, sortOrder))
              .map((item) => (
                <SmoothScrollLink
                  onClick={() => handleLinkClick(item.identifier)}
                  key={item.identifier}
                  to={item.identifier}
                  className={classNames(
                    active === item.identifier && "active",
                    "side-navigation-link"
                  )}
                >
                  <span>{item.name}</span>
                </SmoothScrollLink>
              ))}
          </>
        </ExpandableSection>
      </div>
    </>
  );
};

export default ContentList;
