import { useState, useEffect, useContext } from "react";
import AdminGeneticUpload from "../admin/AdminGeneticUpload";
import EmailDropdown from "./EmailDropdown"
import RegisterModal from "./RegisterModal";
import Container from "react-bootstrap/Container";
import Modal from "./AdminModal";
import { useNavigate } from "react-router-dom";
import { useGetUsers } from "../Auth/FetchAllUsers";
import axios from "axios";
import config from "../../config";
import { useMutation, useQueryClient } from "react-query";
import { Snackbar, Alert } from "@mui/material";
import { Context } from "../../helpers/Context";
import ButtonWithIcon from "../ButtonWithIcon/ButtonWithIcon";
import TrashIcon from "../../assets/svgComponents/TrashIcon";
import EditIcon from "../../assets/svgComponents/EditIcon";
import Tick from "../../assets/svgComponents/Tick";
import getSvgHoverIcon from "../../assets/svgComponents/SvgIconHoverHoc";
import LeftArrow from "../../assets/svgComponents/LeftArrow";
import RightArrow from "../../assets/svgComponents/RightArrow";
import classNames from "classnames";

const getArrowStroke = (flag) =>
  flag ? "rgba(142, 149, 169, .4)" : "rgba(142, 149, 169, 1)";

const AdminTable = () => {
  const queryClient = useQueryClient();
  const { isLoading, error, refetch, data: adminData } = useGetUsers();
  const { setUserId } = useContext(Context);
  const [isRegisterModalOpen, setIsRegisterModalOpen] = useState(false);
  const [errorSnackbarOpen, setErrorSnackbarOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [usersPerPage] = useState(5);
  const [searchName, setSearchName] = useState("");
  const navigate = useNavigate();
  const totalUsers = adminData?.users?.filter((user) => !user.deleted)?.length || 0;
  const [allChunksSentUsers, setAllChunksSentUsers] = useState([]);
  const [editingUserId, setEditingUserId] = useState(null);
  const [editValues, setEditValues] = useState({
    first_name: "",
    last_name: "",
    email: "",
  });

  const totalPages = Math.ceil(totalUsers / usersPerPage);

  const [usersData, setUsersData] = useState(null);

  useEffect(() => {
    if (adminData) {
      setUsersData({
        ...adminData,
        users: adminData.users
          .filter((user) => !user.deleted)
          .sort((a, b) => b.id - a.id),
      });
    }
  }, [adminData]);

  useEffect(() => {
    refetch();
  }, [refetch]);

  useEffect(() => {
    // Only set up polling if there are any users that are pending (either a brand new upload or a re-upload).
    if (allChunksSentUsers.length === 0) return;

    const pollInterval = setInterval(() => {
      if (usersData?.users) {
        // For each user in the polling list, check if they are still pending processing.
        const stillPending = allChunksSentUsers.filter((id) => {
          const user = usersData.users.find((u) => u.id === id);
          // A user is still pending if they haven't received a report OR they are in a re-upload state.
          return user && (!user.has_report || user.pending_reupload);
        });

        // If the list of still pending users has changed, update state.
        if (stillPending.length !== allChunksSentUsers.length) {
          setAllChunksSentUsers(stillPending);
        }

        // Only refetch if there is at least one user still pending.
        if (stillPending.length > 0) {
          refetch();
        }
      }
    }, 3000);

    return () => clearInterval(pollInterval);
  }, [allChunksSentUsers, usersData, refetch]);

  const getCurrentPageUsers = () => {
    const indexOfLastUser = currentPage * usersPerPage;
    const indexOfFirstUser = indexOfLastUser - usersPerPage;
    const filteredUsers = usersData?.users?.filter((user) =>
      `${user?.first_name} ${user?.last_name}`
        .toLowerCase()
        .includes(searchName.toLowerCase())
    );
    return filteredUsers?.slice(indexOfFirstUser, indexOfLastUser);
  };

  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleSeeMore = (user) => {
    if (!user.has_report) {
      alert("User does not have a report yet");
      return;
    }
    setUserId(user.id);
    navigate("/dashboard");
  };

  const deleteUserMutation = useMutation((userId) => {
    return axios.post(
      `${config.apiBaseURL}/api/delete_user/${userId}`,
      {},
      {
        withCredentials: true,
      }
    );
  });

  const handleDeleteUser = async (userId, firstName, lastName) => {
    const confirmDelete = window.confirm(
      `Are you sure you want to delete user ${firstName} ${lastName}?`
    );
    if (confirmDelete) {
      try {
        await deleteUserMutation.mutateAsync(userId);
        queryClient.invalidateQueries("useGetUsers");
        setUsersData((prevUsersData) => ({
          ...prevUsersData,
          users: prevUsersData.users.map((user) =>
            user.id === userId ? { ...user, deleted: true } : user
          ),
        }));
        await refetch();
      } catch (error) {
        console.error("Error deleting user: ", error);
      }
    }
  };

  const handleSendWelcomeEmail = async (email) => {
    try {
      const response = await axios.post(
        `${config.apiBaseURL}/send_welcome_email`,
        { email },
        { withCredentials: true }
      );
      if (response.status === 200) {
        alert("Welcome email has been sent successfully!");
      } else {
        setErrorMessage("An error occurred while sending the welcome email.");
        setErrorSnackbarOpen(true);
      }
    } catch (error) {
      setErrorMessage("An error occurred while sending the welcome email.");
      setErrorSnackbarOpen(true);
    }
  };

  const handleContactUser = async (email) => {
    const confirmSend = window.confirm(
      "You are about to send this user a 'report ready' email. Continue?"
    );
    if (!confirmSend) return;

    try {
      const response = await axios.post(
        `${config.apiBaseURL}/send_report_email`,
        { email },
        {
          withCredentials: true,
        }
      );
      if (response.status === 200) {
        alert("Email has been sent successfully!");
      } else {
        setErrorMessage("An error has occurred while sending the email.");
        setErrorSnackbarOpen(true);
      }
    } catch (error) {
      setErrorMessage("An error has occurred while sending the email.");
      setErrorSnackbarOpen(true);
    }
  };

  const openRegisterModal = () => {
    setIsRegisterModalOpen(true);
  };

  const handleCloseAndInvalidateQuery = async () => {
    setIsRegisterModalOpen(false);
    await refetch();
    setCurrentPage(1);
  };

  const EditIconWrapped = getSvgHoverIcon(EditIcon);
  const TrashIconWrapped = getSvgHoverIcon(TrashIcon);

  const handleEditClick = (user) => {
    setEditingUserId(user.id);
    setEditValues({
      first_name: user.first_name,
      last_name: user.last_name,
      email: user.email,
    });
  };

  const handleSaveClick = async (user_id) => {
    try {
      await axios.post(
        `${config.apiBaseURL}/update_user/${user_id}`,
        { ...editValues },
        { withCredentials: true }
      );
      setUsersData((prevState) => ({
        ...prevState,
        users: prevState.users.map((user) =>
          user.id === user_id ? { ...user, ...editValues } : user
        ),
      }));
      setEditingUserId(null);
    } catch (error) {
      console.error("Error updating user:", error);
      setErrorMessage("Failed to update user");
      setErrorSnackbarOpen(true);
    }
  };

  return (
    <div className={classNames("basic-section", "admin-table-wrap")}>
      <Container>
        <RegisterModal
          isOpen={isRegisterModalOpen}
          handleClose={() => handleCloseAndInvalidateQuery()}
        />
        <div className="search-container">
          <input
            className="search-container-input"
            type="text"
            placeholder="Search"
            value={searchName}
            onChange={(e) => setSearchName(e.target.value)}
          />
          <ButtonWithIcon
            classNameIconOverWrite={"add-button-icon"}
            text="Add New User"
            onClick={openRegisterModal}
          />
        </div>
        <table className="admin-table">
          <thead>
            <tr>
              <th>Name</th>
              <th>Email</th>
              <th>Last Logged In</th>
              <th>Questionnaire</th>
              <th>Effects Report</th>
              <th>Products Report</th>
              <th>Genetic File</th>
              <th>SNP</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {getCurrentPageUsers()?.map((table, index) => (
              <tr key={index}>
                <td>
                  {editingUserId === table.id ? (
                    <>
                      <input
                        type="text"
                        value={editValues.first_name}
                        onChange={(e) =>
                          setEditValues({
                            ...editValues,
                            first_name: e.target.value,
                          })
                        }
                        className="edit-input"
                      />
                      <input
                        type="text"
                        value={editValues.last_name}
                        onChange={(e) =>
                          setEditValues({
                            ...editValues,
                            last_name: e.target.value,
                          })
                        }
                        className="edit-input"
                      />
                    </>
                  ) : (
                    `${table?.first_name} ${table?.last_name}`
                  )}
                </td>
                <td>
                  {editingUserId === table.id ? (
                    <input
                      type="email"
                      value={editValues.email}
                      onChange={(e) =>
                        setEditValues({
                          ...editValues,
                          email: e.target.value,
                        })
                      }
                      className="edit-input"
                    />
                  ) : (
                    table.email
                  )}
                </td>
                <td>{table?.last_logged_in}</td>
                <td>
                  <Modal
                    buttonStyle="admin-table-button"
                    id={table.id}
                    isQuestionnaire={true}
                  />
                </td>
                <td>
                  <button className="admin-table-button" onClick={() => handleSeeMore(table)}>
                    See Effects
                  </button>
                </td>
                <td>
                  <Modal buttonStyle="admin-table-button" id={table.id} />
                </td>
                <td>
                  <AdminGeneticUpload
                    userId={table.id}
                    setErrorSnackbarOpen={setErrorSnackbarOpen}
                    setErrorMessage={setErrorMessage}
                    setAllChunksSentUsers={setAllChunksSentUsers}
                  />
                </td>
                <td>
                  <Modal
                    buttonStyle="admin-table-button"
                    id={table.id}
                    isSnpMapping={true}
                  />
                </td>
                <td>
                  <div className="admin-icons-cell">
                    <EmailDropdown
                      email={table.email}
                      hasReport={table.has_report}
                      pendingReupload={table.pending_reupload}
                      onSendReport={handleContactUser}
                      onSendWelcome={handleSendWelcomeEmail}
                    />
                    <div
                      onClick={() => {
                        if (editingUserId === table.id) {
                          handleSaveClick(table.id);
                        } else {
                          handleEditClick(table);
                        }
                      }}
                      style={{ marginRight: "10px", cursor: "pointer" }}
                    >
                      {editingUserId === table.id ? (
                        <Tick stroke="currentColor" />
                      ) : (
                        <EditIconWrapped />
                      )}
                    </div>

                    {/* Delete user */}
                    <div
                      onClick={() =>
                        handleDeleteUser(
                          table.id,
                          table.first_name,
                          table.last_name
                        )
                      }
                      style={{ cursor: "pointer" }}
                    >
                      <TrashIconWrapped />
                    </div>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        {/* PAGINATION */}
        <div className="pagination">
          <LeftArrow
            onClick={() => {
              if (currentPage > 1) {
                handlePageClick(currentPage - 1);
              }
            }}
            stroke={getArrowStroke(currentPage === 1)}
          />
          <div className="pagination-numbers">
            {Array.from({ length: totalPages }, (_, index) => (
              <span
                key={index}
                className={`page-number ${
                  currentPage === index + 1 ? "active" : ""
                }`}
                onClick={() => handlePageClick(index + 1)}
              >
                {index + 1}
              </span>
            ))}
          </div>
          <RightArrow
            onClick={() => {
              if (currentPage < totalPages) {
                handlePageClick(currentPage + 1);
              }
            }}
            stroke={getArrowStroke(currentPage === totalPages)}
          />
        </div>

        <Snackbar
          open={errorSnackbarOpen}
          autoHideDuration={5000}
          onClose={() => setErrorSnackbarOpen(false)}
          sx={{ marginLeft: "400px" }}
        >
          <Alert onClose={() => setErrorSnackbarOpen(false)} severity="error">
            {errorMessage}
          </Alert>
        </Snackbar>
      </Container>
    </div>
  );
};

export default AdminTable;
