import { useState } from "react";
import "./questionnaire.css";
import CustomInput from "../../ui/Input";
import inputs from "./inputs";
import { validationSchema } from "./questionnaireValidationSchema";
import { useCurrentUser } from "../../components/Auth/UseCurrentUser";
import { Snackbar, Alert } from "@mui/material";
import { Link } from "react-router-dom";
import { useMutation } from "react-query";
import axios from "axios";
import classNames from "classnames";
import { stepsMapping } from "../../helpers/variables";
import config from "../../config";
import ButtonWithIcon from "../ButtonWithIcon/ButtonWithIcon";
import { ThemeProvider } from "@mui/material";
import { basicTheme, editTheme } from "../../helpers/themes";

const steps = ["generalQuestions", "nutrition", "training", "sleepAndIllness"];

const Questionnaire = () => {
  const { data: currentUser, refetch } = useCurrentUser();
  const [answers, setAnswers] = useState(
    currentUser?.user?.questionnaire_response || {}
  );
  const hasReport = currentUser?.user?.has_report;
  const [isFormFinished, setIsFormFinished] = useState(false);
  const [validationErrors, setValidationErrors] = useState({});
  const [errorSnackbarOpen, setErrorSnackbarOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isEdit, setIsEdit] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(
    !!currentUser?.user?.questionnaire_response
  );
  const hasConfirmedHowToRead = currentUser?.user?.has_confirmed_how_to_read;

  const questionnaireMutation = useMutation((values) => {
    return axios.post(`${config.apiBaseURL}/submit_answers`, values, {
      withCredentials: true,
    });
  });

  const handleInputChange = (name, value, stepIndex) => {
    const step = steps[stepIndex];
    setAnswers((prevAnswers) => ({
      ...prevAnswers,
      [step]: {
        ...prevAnswers[step],
        [name]: value,
      },
    }));

    const updatedAnswers = {
      ...(JSON.parse(sessionStorage.getItem("answers")) || {}),
      [step]: {
        ...JSON.parse(sessionStorage.getItem("answers"))?.[step],
        [name]: value,
      },
    };
    sessionStorage.setItem("answers", JSON.stringify(updatedAnswers));

    setValidationErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
  };

  const handleQuestionnaire = async (values) => {
    try {
      await questionnaireMutation.mutateAsync(values);
    } catch (error) {
      setErrorMessage(error.message);
      setErrorSnackbarOpen(true);
    }
  };

  const handleFinish = async () => {
    let validationErrors = {};
    try {
      for (const step of steps) {
        await validationSchema[step].validate(answers[step], {
          abortEarly: false,
        });
      }
      await handleQuestionnaire(answers);
      
      refetch()
      
      setValidationErrors({});
      setIsFormFinished(true);
      setIsSubmitted(true);
      sessionStorage.removeItem("answers");
    } catch (error) {
      if (error.inner) {
        error.inner.forEach((err) => {
          validationErrors[err.path] = err.message;
        });
      }
      setValidationErrors(validationErrors);
      setErrorSnackbarOpen(true);
      setErrorMessage(
        "Could not submit questionnaire, please check your answers."
      );
    }
  };

  const handleEdit = () => {
    setIsEdit(!isEdit);
  };

  const getForm = (step, index) => {
    const currentStep = steps[index];
    const currentStepInputs = inputs.filter((input) => input.step === index);

    return (
      <ThemeProvider theme={isEdit ? editTheme : basicTheme}>
        <div id={currentStep} key={`getform-${stepsMapping[currentStep]}`}>
          <h3 className="basic-page-header">{stepsMapping[currentStep]}</h3>
          <section className="basic-section">
            {currentStepInputs.map((input) => {
              if (input.subQuestions) {
                return (
                  <div key={input.name} className="question-wrap">
                    <p className="question-label">{input.label}</p>

                    {input.subQuestions.map((subinput) => (
                      <CustomInput
                        key={subinput.name}
                        input={subinput}
                        onChange={(name, value) =>
                          handleInputChange(name, value, index)
                        }
                        value={answers[currentStep]?.[subinput.name] || ""}
                        stepIndex={index}
                        isEdit={isEdit}
                        isSubmitted={isSubmitted}
                        error={validationErrors[subinput.name]}
                      />
                    ))}
                  </div>
                );
              } else {
                return (
                  <div key={input.name} className="question-wrap">
                    <CustomInput
                      input={input}
                      onChange={(name, value) =>
                        handleInputChange(name, value, index)
                      }
                      value={answers[currentStep]?.[input.name] || ""}
                      stepIndex={index}
                      isEdit={isEdit}
                      isSubmitted={isSubmitted}
                      error={validationErrors[input.name]}
                    />
                  </div>
                );
              }
            })}
          </section>
        </div>
      </ThemeProvider>
    );
  };

  return (
    <div className={classNames("questionnaire-container", "LeftDiv")}>
      {steps.map((step, index) => getForm(step, index))}
      <div className="questionnaire-action-buttons">
        {!isSubmitted && (
          <ButtonWithIcon
            onClick={handleFinish}
            classNameIconOverWrite="save-btn-icon"
            text="Submit"
          />
        )}
        {isSubmitted && (
          <div className="questionnaire-edit-btns-wrap">
            <button
              className="button questionnaire-edit-button"
              onClick={handleEdit}
            >
              {isEdit ? "Cancel" : "Edit"}
            </button>

            {isEdit && (
              <ButtonWithIcon
                classNameIconOverWrite="save-btn-icon"
                onClick={() => {
                  handleFinish();
                  setIsEdit(false);
                }}
                text="Submit"
                disabled={!isEdit}
              />
            )}
          </div>
        )}
        {isSubmitted && !isEdit && ((hasConfirmedHowToRead && hasReport) || !hasConfirmedHowToRead) && (
          <Link to={hasConfirmedHowToRead ? "/dashboard" : "/how_to_read"}>
            <ButtonWithIcon
              classNameOverWrite="questionnaire-button"
              classNameIconOverWrite={hasConfirmedHowToRead ? "save-btn-icon" : undefined}
              text="Next"
            />
          </Link>
        )}
      </div>
      <Snackbar
        open={errorSnackbarOpen}
        autoHideDuration={5000}
        onClose={() => setErrorSnackbarOpen(false)}
        sx={{ marginLeft: "400px" }}
      >
        <Alert onClose={() => setErrorSnackbarOpen(false)} severity="error">
          {errorMessage}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default Questionnaire;
