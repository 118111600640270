import React, { useRef } from "react";
import DropdownSelect from "../DropdownSelect/DropdownSelect";
import MailIcon from "../../assets/svgComponents/MailIcon";
import getSvgHoverIcon from "../../assets/svgComponents/SvgIconHoverHoc";

const MailIconWrapped = getSvgHoverIcon(MailIcon);

interface EmailDropdownProps {
  email: string;
  hasReport: boolean;
  pendingReupload: boolean;
  onSendReport: (email: string) => void;
  onSendWelcome: (email: string) => void;
}

const EmailDropdown: React.FC<EmailDropdownProps> = ({
  email,
  hasReport,
  pendingReupload,
  onSendReport,
  onSendWelcome,
}) => {
  const [dropdownVisible, setDropdownVisible] = React.useState(false);
  const exclusionRef = useRef<HTMLDivElement | null>(null);

  const toggleDropdown = () => {
    setDropdownVisible(!dropdownVisible);
  };

  const options = [
    {
      name: "Send Welcome Email",
      value: "welcome_email",
      onClick: () => {
        setDropdownVisible(false);
        if (window.confirm(`You're about to send a welcome email to ${email}. Continue?`)) {
          onSendWelcome(email);
        }
      },
    },
  ];

  if (hasReport && !pendingReupload) {
    options.push({
      name: "Send Report Email",
      value: "report_email",
      onClick: () => {
        setDropdownVisible(false);
        if (window.confirm(`You're about to send a report email to ${email}. Continue?`)) {
          onSendReport(email);
        }
      },
    });
  }

  return (
    <div ref={exclusionRef} style={{ position: "relative", display: "inline-block" }}>
      <div onClick={toggleDropdown} style={{ cursor: "pointer" }}>
        <MailIconWrapped />
      </div>
      {dropdownVisible && (
        <DropdownSelect
          toggleHidden={() => setDropdownVisible(false)}
          options={options}
          classNameOverwrite="EmailDropdown"
          active="" 
          exclusionRefs={[exclusionRef]}
        />
      )}
    </div>
  );
};

export default EmailDropdown;
